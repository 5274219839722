<template>
  <div class="brand-info">
    <h1 class="brand-info__title">{{ dataSeo?.content_title }}</h1>
    <h3 class="brand-info__description">
      {{ dataSeo?.content_description }}
    </h3>
    <div v-if="$device.isMobileOrTablet" class="brand-info__group-mobile">
      <div class="list-reserve">
        <a
          href="//www.dmca.com/Protection/Status.aspx?ID=f362511c-0d21-4b76-af37-f633d9a377ba"
          title="DMCA.com Protection Status"
          class="dmca-badge"
          target="_blank"
        >
          <CommonBaseImg src="/assets/images/dmca-mobile.webp" alt="DMCA.com Protection Status" />
        </a>
        <CommonBaseImg src="/assets/images/copyright-logo.webp" class="reserve-image" alt="copy right" />
      </div>
      <div class="copy-right">Copyright © 2024 Powered By {{ $config.public.BRANCH_NAME }} All Rights Reserved.</div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import { getFooterContent } from '@/constants/footer'

const { SLOGAN, DESCRIPTION } = getFooterContent()
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const { $device, $pinia } = useNuxtApp()

const store = useAppStore($pinia)
const { seoData } = storeToRefs(store)
const route = useRoute()
const dataSeo = computed(() => {
  return (
    seoData.value.find((item) => route.path === `/${item.alias}`) || seoData.value.find((item) => item.alias === '/')
  )
})
</script>

<style scoped lang="scss" src="assets/scss/components/common/branch-info.scss" />
